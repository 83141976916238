body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input[type='checkbox'].checkbox-1 {
    zoom: 2.4;
}

input[type='checkbox'].checkbox-2 {
    zoom: 2.2;
    padding-top: 10px;
}

.checkbox-3 input[type='checkbox'] {
    margin-right: 10px;
    width: 22px;
    height: 22px;
}
/* Or transform:"scale(1.5)" */

.form-check-label {
    cursor: pointer;
}

.checkbox-xs {
    height: 1.5rem !important;
    width: 2.5rem !important;
    border-radius: 1rem !important;
}

.checkbox-md {
    height: 1.9rem !important;
    width: 3rem !important;
    border-radius: 1rem !important;
}

.checkbox-red.form-check-input:checked {
    background-color: #b02a37;
    border-color: #b02a37;
}

.pointer {
    cursor: pointer;
}

.btn-outline-general-blue {
    background-color: #ffffff !important;
    border: 1px solid #4a6da7 !important;
    color: #4a6da7 !important;
}

.btn-outline-general-blue:hover {
    background-color: #25457d !important;
    border: 1px solid #25457d !important;
    color: white !important;
}

.react-confirm-alert-overlay {
    z-index: 9999 !important;
}

.btn-outline-general-blue {
    background-color: #ffffff !important;
    border: 1px solid #4a6da7 !important;
    color: #4a6da7 !important;
}

.btn-outline-general-blue:hover {
    background-color: #25457d !important;
    border: 1px solid #25457d !important;
    color: white !important;
}

.btn-general-blue {
    background-color: #4a6da7 !important;
    border: 1px solid #4a6da7 !important;
    color: white !important;
}

.btn-general-blue:hover {
    background-color: #25457d !important;
    border: 1px solid #25457d !important;
}

.btn-general-red {
    background-color: #b02a37 !important;
    border: 1px solid #b02a37 !important;
    color: white !important;
}

.btn-general-red:hover {
    background-color: #921b27 !important;
    border: 1px solid #921b27 !important;
}

.btn-grey {
    background-color: #adb5bd !important;
}

.btn-green {
    background-color: #3c7f8b !important;
}

.btn-yellow {
    background-color: #d68f00 !important;
}

.btn-size12 {
    font-size: 1.2rem !important;
}

.bg-opacity {
    --bs-bg-opacity: 0.7 !important;
}

.bg-grey {
    background-color: #adb5bd !important;
}

.bg-general-blue {
    background-color: #4a6da7 !important;
}

.bg-general-red {
    background-color: #b02a37 !important;
}

.bg-green {
    background-color: #3c7f8b !important;
}

.bg-yellow {
    background-color: #d68f00 !important;
}

/* statistics animation */

.animate-me {
    -webkit-animation: bgcolorchange 1s infinite; /* Chrome, Safari, Opera */
    animation: 1s infinite bgcolorchange;
}

@keyframes bgcolorchange {
    0% {
        background-color: red;
    }
    50% {
        background-color: black;
    }
    100% {
        background-color: red;
    }
}
/* Chrome, Safari, Opera */
@-webkit-keyframes bgcolorchange {
    0% {
        background: red;
    }
    50% {
        background: black;
    }
    100% {
        background: red;
    }
}

/* Admins Page */

.page-item.active span {
    background-color: #4a6da7 !important;
    border: 1px solid #4a6da7 !important;
    color: white !important;
    font-size: 1.2rem;
}

.page-item.active span:hover {
    background-color: #25457d !important;
    border: 1px solid #25457d !important;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: #4a6da7 !important;
    color: white !important;
}

#adminsPageDropdownBtn {
    background-color: #4a6da7 !important;
    border: 1px solid #4a6da7 !important;
    color: white !important;
    font-size: 1.2rem;
}

#adminsPageDropdownBtn:hover {
    background-color: #25457d !important;
    border: 1px solid #25457d !important;
}

.hover-primary:hover {
    background-color: #4a6da7 !important;
    border: 1px solid #4a6da7 !important;
    color: white;
}

.maxw-400 {
    max-width: 400px !important;
}

.custom-card {
    cursor: pointer;
    transition: box-shadow 0.3s ease;
}

.custom-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.c-pointer {
    cursor: pointer;
}

.calendar {
    background-color: #f8f9fa;
    border-radius: 5px;
    padding: 5px;
}

.calendar-header {
    background-color: #4a6da7;
    color: white;
    border-radius: 5px;
}

.calendar-header-time {
    width: 70px;
}

.calendar-header-day {
    padding: 10px 0;
    width: 100px;
}

.calendar-row {
    border-bottom: 1px solid #ddd;
}

.calendar-time {
    background-color: #54595e;
    color: #ffffff;
    font-weight: bold;
    width: 70px;
    display: flex;
    font-size: 0.75em;
    justify-content: center;
    flex-direction: column;
    height: 60px;
    border-radius: 5px;
    line-height: 12px;
}

.calendar-cell {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    width: 100px;
}

.calendar-assignment {
    background-color: #4a6da7;
    color: #ffffff;
    font-weight: bold;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 60px;
    padding: 5px;
    text-wrap: nowrap;
    font-size: 0.8em;
    overflow: hidden;
    width: 95%;
    svg {
        display: flex;
        justify-content: center;
        width: 100%;
        font-size: 1.7em;
    }
}

.calendar-assignment:hover {
    background-color: #25457d !important;
    cursor: pointer;
}

/* SkeletonLoader.css */
.skeleton-loader {
    background-color: #e0e0e0;
    background-image: linear-gradient(90deg, #e0e0e0, #f0f0f0, #e0e0e0);
    background-size: 200% 100%;
    background-position: 100% 0;
    border-radius: 4px;
    animation: shimmer 1.5s infinite linear;
}

@keyframes shimmer {
    0% {
        background-position: -100% 0;
    }
    100% {
        background-position: 100% 0;
    }
}

@media (max-width: 768px) {
    .table-desktop {
        display: none;
    }
    .card-mobile {
        display: block;
    }
}

@media (min-width: 769px) {
    .table-desktop {
        display: table;
    }
    .card-mobile {
        display: none;
    }
}

@keyframes slideLeft {
    0%, 100% {
        transform: translateX(0%);
    }
    20% {
        transform: translateX(-20%);
    }
}
  
.marquee {
    animation: slideLeft 10s linear infinite;
}

.using-line-breaks {
    white-space: pre-wrap;
}
